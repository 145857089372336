<template>
    <div>
        <!-- <customers-visual-visitor-old v-if="isReseller == false"></customers-visual-visitor-old> -->
        <customers-visual-visitor v-if="isReseller == false"></customers-visual-visitor>
        <customers-reseller v-if="isReseller == true"></customers-reseller>
    </div>
</template>

<script>
// import CustomersVisualVisitorOld from "../../components/customers/CustomersVisualVisitorOld";
import CustomersVisualVisitor from "../../components/customers/CustomersVisualVisitor";
import CustomersReseller from "../../components/customers/CustomersReseller";

export default {
    components: {
        // CustomersVisualVisitorOld,
        CustomersVisualVisitor,
        CustomersReseller
    },
    data() {
        return {
            isReseller:  null
        }
    },
    created() {
        let uid = this.$route.params.id
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isReseller = token[uid].isReseller
    }
}
</script>

<style>

</style>
