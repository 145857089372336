<template>
<div>
    <div class="vx-row">
        <div class="vx-col w-full mt-8">
            <vx-card>
                <div class="table-title">
                    <h2>Customers</h2>
                    <div class="actions-button">
                        <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
                            <vs-button class="bg-actionbutton" type="filled">
                                <download-excel class="btn btn-default" :data="excelData" :exportFields="excelFields" worksheet="Reseller" name="Reseller.xls">
                                    <img src="@/assets/images/excel.svg" />
                                </download-excel>
                            </vs-button>
                        </vx-tooltip>
                    </div>
                </div>
                <div class="add-new-number items-center  mb-4">
                    <vs-button title="Add New Customer" @click="addCustomerPopup = true, setDefaultPlan('addCustomer')" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mr-3 ml-0 bg-actionbutton float-right">
                        {{ LabelConstant.buttonLabelAddNewCustomer }}
                    </vs-button>
                     <div class="pb-0">
                        <p class="text-danger d-block" v-if="!resellerIsLegacyOnly">
                            You have {{activeFreeTrials}} active free trials out of {{freeTrialsAvailableToProvision}} available
                        </p>
                     </div>
                </div>
                <div class="common-table find-people">
                    <div class="table-row-select">
                        <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                            <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                        </vs-select>
                    </div>
                    <vs-table :data="resellerCustomers" search :max-items="perPage" pagination ref="tableDataResellerCustomer" id="tableDataResellerCustomer">
                        <template slot="thead">
                            <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                            <vs-th sort-key="Company_Name">{{ LabelConstant.tableHeaderLabelCompanyName }}</vs-th>
                            <vs-th sort-key="Domain_Name">{{ LabelConstant.tableHeaderLabelDomainName }}</vs-th>
                            <vs-th sort-key="Free_Trial" width="110">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                            <vs-th sort-key="Plan_Type">{{ LabelConstant.tableHeaderLabelPlanType }}</vs-th>
                            <vs-th sort-key="sortableExpireDate">{{ LabelConstant.tableHeaderLabelFreeTrialExp }}</vs-th>
                            <vs-th sort-key="sortableCreatedDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                            <vs-th sort-key="Company_Id">{{ LabelConstant.tableHeaderLabelAccountID }}</vs-th>
                        </template>
                        <template slot-scope="{ data }">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td width="120">
                                    <div class="table-action three-action">
                                        <vs-button :title="LabelConstant.buttonTitleLoginasCustomer" color="secondary" type="filled" icon-pack="feather" icon="icon-lock" class="mr-2 float-left" @click="loginAsCustomer(tr.Id)"></vs-button>
                                        <vs-button @click="getCustomer(tr.Id)" color="primary" :title="LabelConstant.buttonTitleEdit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left"></vs-button>
                                        <vs-button @click=" deleteCustomerPopup = true, deleteCustomerId = tr.Id, deleteCustomerName = tr.Company_Name" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </div>
                                </vs-td>
                                <vs-td :data="tr.Company_Name">
                                    {{ tr.Company_Name }}
                                </vs-td>
                                <vs-td :data="tr.Domain_Name">
                                    {{ tr.Domain_Name }}
                                </vs-td>
                                <vs-td :data="tr.Free_Trial">
                                    <vs-chip color="warning" v-if="tr.Free_Trial">Free Trial</vs-chip>
                                    <vs-chip color="success" v-if="!tr.Free_Trial">Paid</vs-chip>
                                </vs-td>
                                <vs-td :data="tr.Plan_Type">
                                    {{ tr.Plan_Type }}
                                </vs-td>
                                <vs-td :data="tr.sortableExpireDate">
                                    {{ tr.Free_Trial_Expiration }}
                                </vs-td>
                                <vs-td :data="tr.sortableCreatedDate">
                                    {{ tr.Date_Created }}
                                </vs-td>
                                <vs-td :data="tr.Company_Id">
                                    {{ tr.Company_Id }}
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
                        Showing {{ showingDataFrom }} to {{ showingDataTo }} of {{ resellerCustomers.length|tableRecordNumberFormatter }} entries
                    </p>
                    <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
                        Showing {{ showingDataFrom }} to {{ showingSearchedDataTo }} of {{ totalSearchedData|tableRecordNumberFormatter }} entries (filtered from {{ resellerCustomers.length|tableRecordNumberFormatter }} entries)
                    </p>
                    <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">
                        Showing 0 to 0 of 0 entries
                    </p>
                </div>
            </vx-card>
        </div>
    </div>

    <!-- Delete Cuatomer Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteCustomerPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Delete Customer?</h4>
                    <span class="deleted-no">{{ deleteCustomerName }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button color="danger" type="filled" @click="deleteCustomer()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deleteCustomerPopup = false,deleteCustomerId = null,deleteCustomerName = null" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Add/Edit Customer Validation Warning -->
    <vs-popup class="close-open" title="Warning" :active.sync="addEditCustomerValidationWarningPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full text-left">
                    <p>Missing information found, please validate all required fields have been updated. </p>
                </div>
            </div>
        </template>
        <div class="text-center mt-6 mb-3">
            <vs-button @click="addEditCustomerValidationWarningPopup=false" color="primary"  type="filled"> Okay </vs-button>
        </div>
    </vs-popup>

    <!-- Create Customer -->
    <vs-popup class="popup-width" title="Create New Customer" :active.sync="addCustomerPopup">
        <template>
            <form data-vv-scope="createCustomerForm">
                <div class="vx-row">
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Company Name" v-model="customerCompanyName" name="customerCompanyName" v-validate="'required'" :oninput="onInputCompanyName()"/>
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerCompanyName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Domain Name" v-model="customerDomainName" name="customerDomainName" v-validate="'required'" :oninput="onInputCompanyDomain()"/>
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerDomainName")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <vs-checkbox v-model="customerFreeTrial" :disabled="true">Free Trial</vs-checkbox>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="First Name" v-model="customerFirstName" name="customerFirstName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerFirstName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Last Name" v-model="customerLastName" name="customerLastName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerLastName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Email" v-model="customerEmail" name="customerEmail" v-validate="'required|email'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Phone" v-model="customerPhone" name="customerPhone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <span class="vs-input--label">Role</span>
                        <v-select v-model="customerRole" :label="'Role_Name'" :options="roleOptions" :reduce="roleOptions => roleOptions.Id" :clearable="false" />
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input type="password" class="w-full" label="New Password" ref="customerNewPassword" v-model="customerNewPassword" name="customerNewPassword" v-validate="'required|min:6'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerNewPassword")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input type="password" class="w-full" label="Confirm Password" v-model="customerConfirmPassword" name="customerConfirmPassword" v-validate="'required|min:6|confirmed:customerNewPassword'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerConfirmPassword")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <span class="vs-input--label">Time Zone</span>
                        <v-select v-model="customerTimeZone" :options="timeZoneoptions" :reduce="timeZoneoptions => timeZoneoptions.Key" label="Value" :clearable="false" name="customerTimeZone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerTimeZone")}}</span>
                    </div>

                    <div class="vx-col w-full mb-6">
                        <span class="vs-input--label">Select Plan</span>
                        <v-select v-model="customerPlanId" :options="customerFreePlanOptions" :reduce="options => options.Key" label="Value" name="customerPlanId" v-validate="'required'" @input="getAllowedIdentifiedVisitorInfo()"/>
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.customerPlanId")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-4" v-if="isPlusEmployeeIncludedInPlan">
                        <vs-checkbox v-model="isPlusEmployeeEnabled" @input="changePlusEmployee()">Enable +Employee</vs-checkbox>
                    </div>
                    <div class="vx-col w-full mb-6" v-if="isConsumerPlan && isIdentifiedVisitorVisible">
                        <span class="vs-input--label">Number of allowed identified visitors (0 to X)</span>
                        <vs-input class="w-full" v-model="identifiedVisitor" name="identifiedVisitor" v-validate="'required|numeric|min:0'"/>
                        <span class="text-danger text-sm">{{errors.first("createCustomerForm.identifiedVisitor")}}</span>
                    </div>
                    <div class="vx-col w-full my-6" v-if="isConsumerPlan || isPlusEmployeeEnabled">
                        <vs-checkbox v-model="cookieConsentBanner" :disabled="addIsCookieConsentReadonly">Add Cookie Consent Banner to Company's Website</vs-checkbox>
                    </div>
                    <div class="vx-col w-full mb-6" v-if="cookieConsentBanner && (isConsumerPlan || isPlusEmployeeEnabled)">
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Name" v-model="companyName" name="companyName" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyName")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Domain" v-model="companyDomain" name="companyDomain" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyDomain")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Address" v-model="companyAddress" name="companyAddress" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyAddress")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Address2" v-model="companyAddress2"/>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company City" v-model="companyCity" name="companyCity" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyCity")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company State" v-model="companyState" name="companyState" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyState")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Zipcode" v-model="companyZipcode" name="companyZipcode" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyZipcode")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Phone" v-model="companyPhone" name="companyPhone" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companyPhone")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Support Email" v-model="companySupportEmail" name="companySupportEmail" v-validate="'required|email'"/>
                                <span class="text-danger text-sm">{{errors.first("createCustomerForm.companySupportEmail")}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="vx-col w-full lg:w-1/2 mb-6">
                        <vs-checkbox v-model="customerActive">Active</vs-checkbox>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full">
                        <vs-checkbox v-model="customerSendSetupEmail">Send Setup Email</vs-checkbox>
                    </div>
                </div>
            </form>
        </template>
        <div class="float-right mt-6 sm:w-auto w-full mb-8">
            <vs-button type="filled" class="sm:w-auto ml-0 mb-2 sm:mb-0 w-full" color="primary" @click="createCustomer()">{{ LabelConstant.buttonLabelCreateCustomer }}</vs-button>
            <vs-button @click="cancelCreateCustomer()" color="grey" type="border" class="grey-btn sm:ml-4 ml-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Enable Beta Cuatomer Popup -->
    <vs-popup class="tag-info" :active.sync="enableBetaPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col input-text w-full">
                    <div class="popup-icon">
                        <img src="../../../src/assets/images/complate.png" />
                    </div>
                    <h3 class="sub-popup-title">{{ enableBetaMessage }}</h3>
                    <span class="sub-text">{{ customerCompanyName }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="enableBeta()" class="bg-actionbutton">{{ enableBetaButton }}</vs-button>
            <vs-button @click="enableBetaPopup = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Extend Free Trial -->
    <vs-popup title="Extend Free Trial" :active.sync="extendFreeTrial">
        <div class="free-trial">
            <p>The New Trail Expiration is : {{ extendedFreeTrialDate }}</p>
            <div class="celander-expiration">
                <h3>Select New Free Trial Expiration</h3>
                <div id="simple-calendar-app">
                    <datepicker :inline="true" v-model="extendedFreeTrialDate" :format="customFormatter"></datepicker>
                </div>
            </div>
        </div>
        <div class="float-right mt-2 sm:w-auto w-full mb-8">
            <vs-button type="filled" class="bg-actionbutton sm:w-auto ml-0 mb-2 sm:mb-0 w-full small-btn" @click="updateFreeTrialExpiration()">{{ LabelConstant.buttonLabelExtendTrial }}</vs-button>
            <vs-button @click="extendFreeTrial = false" color="grey" type="border" class="sm:ml-4 ml-0 sm:w-auto w-full small-btn grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Disable Marketing Popup -->
    <vs-popup class="delete-popup" :active.sync="disableMarketingPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Disable Email Marketing?</h4>
                    <span class="deleted-no">{{ customerCompanyName }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button class="bg-actionbutton" type="filled" @click="turnOffEmailMarketing()">{{ LabelConstant.buttonLabelDisableEmailMarketing }}</vs-button>
            <vs-button @click="disableMarketingPopup = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- End Paid Account Popup -->
    <vs-popup class="delete-popup" :active.sync="endPaidAccountPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>End Paid Account?</h4>
                    <span class="deleted-no">{{ customerCompanyName }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button class="bg-actionbutton" type="filled" @click="deleteCustomerBackToFreeTrial()">{{ LabelConstant.buttonLabelEndPaidAccount }}</vs-button>
            <vs-button @click="endPaidAccountPopup = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Terminate Account Popup -->
    <vs-popup class="delete-popup" :active.sync="terminateAccountPopup">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Terminate Account?</h4>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button class="bg-actionbutton" type="filled" @click="cancelVVAccount()">{{ LabelConstant.buttonLabelTerminate }}</vs-button>
            <vs-button @click="terminateAccountPopup = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Confirmation Purchase or Cancel Additional Custom Campaign Popup -->
    <vs-popup class="transaction-popup" :active.sync="addDeleteCustomCampaignConfirmActive">
        <div class="transaction-details">
            <div class="popup-icon">
                <img v-show="userActionType == 'add'" src="../../assets/images/warning.svg">
                <img v-show="userActionType == 'delete'" src="../../assets/images/trash.svg">
            </div>
            <h2 v-show="userActionType == 'add'">Purchase Additional Custom Campaign?</h2>
            <h2 v-show="userActionType == 'delete'">Remove Custom Campaign?</h2>
            <p v-show="userActionType == 'add'">{{additionalCustomerCampaign}}</p>
            <p v-show="userActionType == 'delete'">{{deletionCustomCampaign}}</p>
            <div class="float-left mt-6 w-full text-center">
                <vs-button @click="purchaseAdditionalCampaign()" v-show="userActionType == 'add'" color="success" class="mt-0 sm:w-auto w-full" type="filled">Purchase</vs-button>
                <vs-button @click="deletePurchasedCampaign()" v-show="userActionType == 'delete'" color="danger"  class="mt-0 sm:w-auto w-full"  type="filled">Remove</vs-button>
                <vs-button @click="addDeleteCustomCampaignConfirmActive=false" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </div>
    </vs-popup>

    <!-- Purchase or Cancel Additional Custom Campaigns popup -->
    <vs-popup class="popup-width" title="Purchase or Cancel Additional Custom Campaigns" :active.sync="purchaseAdditionalCampaignPopup">
        <template>
            <div class="vx-row mb-3" >
                <div class="vx-col md:w-1/2 w-full">
                    <ul class="account-summary p-4">
                        <li class="pb-2 w-full">
                            <h4>Custom Campaigns included with plan</h4>
                            <span>{{customCampaignsIncluded}}</span>
                        </li>
                        <li class="pl-0 pb-2 w-full">
                            <h4>Additional Custom Campaigns purchased</h4>
                            <span>{{customCampaignsPurchased}}</span>
                        </li>
                        <li class="w-full pb-2">
                            <h4>Total Custom Campaigns Provisioned</h4>
                            <span>{{customCampaignsProvisioned}}</span>
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section">
                    <div class="card-title">
                        <h2> Options </h2>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full mb-8">
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="add" :color="addCustomCampaignRadioColor" @change="changeColor()">
                                    <span :class="addCustomCampaignTextColor">I would like to purchase an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Purchase additional custom Campaigns</span>
                                        <v-select
                                          v-model="additionalCustomerCampaign"
                                          :options="additionalCustomCampaignOptions"
                                          :clearable="false"
                                        />
                                        <span>$199/month</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="vx-col w-full mb-8" v-show="campaignsAvailableToDelete > 0">
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="delete" :color="deleteCustomCampaignRadioColor" @change="changeColor()">
                                    <span :class="deletionCustomCampaignTextColor">I would like to cancel an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Cancel additional custom Campaigns</span>
                                        <v-select
                                          v-model="deletionCustomCampaign"
                                          :options="deletionCustomCampaignOptions"
                                          :clearable="false"
                                        />
                                        <span>$-199/month</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="float-right mb-8">
            <vs-button @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'add'" type="filled" color="primary">Purchase Additional Campaigns</vs-button>
            <vs-button @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'delete'" color="danger" type="filled">Remove Additional Campaigns</vs-button>
            <vs-button @click="cancelPurchase()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Create New Reseller -->
    <vs-popup class="popup-width" title="Create New Reseller" :active.sync="createResellerPopup">
        <template>
            <form data-vv-scope="createResellerForm">
                <div class="vx-row">
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Company Name" v-model="resellerCompanyName" name="resellerCompanyName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerCompanyName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Legal Name" v-model="resellerLegalName" name="resellerLegalName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerLegalName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Domain Name" v-model="resellerDomainName" name="resellerDomainName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerDomainName")}}</span>
                        <p class="mt-1">This is your private label address. Use this address for you and your customers to access Visual Visitor</p>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Actual Domain Name" v-model="resellerActualDomainName" name="resellerActualDomainName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerActualDomainName")}}</span>
                        <p class="mt-1">This is your domain name of your address. This will be used in email communications to your customers.</p>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Main Phone" v-model="resellerMainPhone" name="resellerMainPhone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerMainPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Sales Phone" v-model="resellerSalesPhone" name="resellerSalesPhone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerSalesPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Support Phone" v-model="resellerSupportPhone" name="resellerSupportPhone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerSupportPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="General Email" v-model="resellerGeneralEmail" name="resellerGeneralEmail" v-validate="'required|email'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerGeneralEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Sales Email" v-model="resellerSalseEmail" name="resellerSalseEmail" v-validate="'required|email'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerSalseEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Support Email" v-model="resellerSupportEmail" name="resellerSupportEmail" v-validate="'required|email'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerSupportEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Send As Email" v-model="resellerSendAsEmail" name="resellerSendAsEmail" v-validate="'required|email'" />
                        <span class="text-danger text-sm">{{errors.first("createResellerForm.resellerSendAsEmail")}}</span>
                    </div>
                </div>
            </form>
        </template>
        <div class="float-right mt-6 sm:w-auto w-full mb-8">
            <vs-button type="filled" class="sm:w-auto ml-0 mb-2 sm:mb-0 w-full bg-actionbutton" @click="createOrganization()">{{ LabelConstant.buttonLabelCreateReseller }}</vs-button>
            <vs-button @click="cancelCreateOrganization()" color="grey" type="border" class="grey-btn sm:ml-4 ml-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Edit Customer -->
    <vs-popup class="popup-width close-open" title="Edit Customer" :active.sync="editCustomerPopup">
        <vs-button
            @click="cancelCreateCustomer()"
            color="secondary"
            type="filled"
            class="mb-8 float-right close-popup-btn"
            >{{ LabelConstant.buttonLabelClose }}
        </vs-button>
        <ul class="cus-action mb-8" v-if="initialRole == 'Global Admin'">
            <li>
                <vx-tooltip color="success" text="Enable Email Beta">
                    <vs-button color="success" type="border" class="small-btn" @click="enableBetaType = 'email',enableBetaPopup = true,enableBetaMessage = 'Enable Client Email Beta?',enableBetaButton = 'Enable Client Email Beta'">
                        {{ LabelConstant.buttonLabelEmailBeta }}
                    </vs-button>
                </vx-tooltip>
            </li>
            <li>
                <vx-tooltip color="success" text="Enable Zapier Beta">
                    <vs-button color="success" type="border" class="small-btn" @click="enableBetaType = 'zapier',enableBetaPopup = true,enableBetaMessage = 'Enable Zapier Beta?',enableBetaButton = 'Enable Zapier Beta'">
                        {{ LabelConstant.buttonLabelZapierBeta }}
                    </vs-button>
                </vx-tooltip>
            </li>
            <li>
                <vx-tooltip color="success" text="Enable Contact Exp Beta">
                    <vs-button color="success" type="border" class="small-btn" @click="enableBetaType = 'contact',enableBetaPopup = true,enableBetaMessage = 'Enable Contact Export Beta?',enableBetaButton = 'Enable Export Beta'">
                        {{ LabelConstant.buttonLabelContactExpBeta }}
                    </vs-button>
                </vx-tooltip>
            </li>
            <li>
                <vx-tooltip color="success" text="Enable Call Tracking Beta">
                    <vs-button color="success" type="border" class="small-btn" @click="enableBetaType = 'callTracking',enableBetaPopup = true,enableBetaMessage = 'Enable Call Tracking Beta?',enableBetaButton = 'Enable Call Tracking Beta'">
                        {{ LabelConstant.buttonLabelCallTrackingBeta }}
                    </vs-button>
                </vx-tooltip>
            </li>
        </ul>
        <template>
            <form data-vv-scope="updateCustomerForm" class="customer-form">
                <div class="vx-row">
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Company Name" v-model="customerCompanyName" name="customerCompanyName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerCompanyName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Domain Name" v-model="customerDomainName" name="customerDomainName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerDomainName")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6 flex">
                        <vs-checkbox v-model="customerFreeTrial" :disabled="true">Free Trial</vs-checkbox>
                        <vs-button v-if="customerFreeTrial" color="primary" type="filled" class="sm:w-auto ml-0 mb-2 sm:mb-0 sm:ml-3 w-full" @click="convertFreeToPaidPlanPopup = true, setDefaultPlan('convertCustomer')">{{ LabelConstant.buttonLabelEndFreeTrialMakePaidAccount }}</vs-button>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="First Name" v-model="customerFirstName" name="customerFirstName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerFirstName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Last Name" v-model="customerLastName" name="customerLastName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerLastName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Email" v-model="customerEmail" :disabled="true" />
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input class="w-full" label="Phone" v-model="customerPhone" name="customerPhone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerPhone")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <span class="vs-input--label">Role</span>
                        <v-select v-model="customerRole" :label="'Role_Name'" :options="editRoleOptions" :reduce="editRoleOptions => editRoleOptions.Id" :clearable="false" :noDrop="true" />
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input type="password" class="w-full" label="New Password" ref="customerNewPassword" v-model="customerNewPassword" name="customerNewPassword" v-validate="'required|min:6'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerNewPassword")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-6">
                        <vs-input type="password" class="w-full" label="Confirm Password" v-model="customerConfirmPassword" name="customerConfirmPassword" v-validate="'required|min:6|confirmed:customerNewPassword'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerConfirmPassword")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6">
                        <span class="vs-input--label">Time Zone</span>
                        <v-select v-model="customerTimeZone" :options="timeZoneoptions" :reduce="timeZoneoptions => timeZoneoptions.Key" label="Value" :clearable="false" name="customerTimeZone" v-validate="'required'" />
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerTimeZone")}}</span>
                    </div>

                    <div class="vx-col w-full mb-6" v-if="!customerPreventDelete || customerFreeTrial">
                        <span class="vs-input--label">Select Plan</span>
                        <v-select v-model="customerPlanId" :options="customerFreeTrial ? customerFreePlanOptions : customerPaidPlanOptions" :reduce="options => options.Key" label="Value" :clearable="false" name="customerPlanId" v-validate="'required'" @input="getAllowedIdentifiedVisitorInfo()"/>
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.customerPlanId")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-4" v-if="isPlusEmployeeIncludedInPlan">
                        <vs-checkbox v-model="isPlusEmployeeEnabled" @input="changePlusEmployee()">Enable +Employee</vs-checkbox>
                    </div>
                    <div class="vx-col w-full mb-6" v-if="isConsumerPlan && isIdentifiedVisitorVisible">
                        <span class="vs-input--label">Number of allowed identified visitors (0 to X)</span>
                        <vs-input class="w-full" v-model="identifiedVisitor" name="identifiedVisitor" v-validate="'required|numeric|min:0'"/>
                        <span class="text-danger text-sm">{{errors.first("updateCustomerForm.identifiedVisitor")}}</span>
                    </div>
                    <div class="vx-col w-full mb-6" v-if="isConsumerPlan && isIdentifiedVisitorVisible && !customerFreeTrial">
                        <span class="vs-input--label">Cost per allowed identified visitor</span>
                        <vue-numeric class="w-full vs-inputx vs-input--input normal hasValue" v-model="identifiedVisitorUnitCost" currency="$" separator="," output-type="Number" :precision="2"></vue-numeric>
                    </div>
                    <div class="vx-col w-full my-6" v-if="isConsumerPlan || isPlusEmployeeEnabled">
                        <vs-checkbox v-model="cookieConsentBanner" :disabled="!isTrusted">Add Cookie Consent Banner to Company's Website</vs-checkbox>
                    </div>
                    <div class="vx-col w-full mb-4" v-if="cookieConsentBanner && (isConsumerPlan || isPlusEmployeeEnabled)">
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Name" v-model="companyName" name="companyName" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyName")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Domain" v-model="companyDomain" name="companyDomain" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyDomain")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Address" v-model="companyAddress" name="companyAddress" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyAddress")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Address2" v-model="companyAddress2"/>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company City" v-model="companyCity" name="companyCity" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyCity")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company State" v-model="companyState" name="companyState" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyState")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Zipcode" v-model="companyZipcode" name="companyZipcode" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyZipcode")}}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Phone" v-model="companyPhone" name="companyPhone" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companyPhone")}}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" label="Company Support Email" v-model="companySupportEmail" name="companySupportEmail" v-validate="'required|email'"/>
                                <span class="text-danger text-sm">{{errors.first("updateCustomerForm.companySupportEmail")}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="vx-col w-full mb-10" v-if="initialRole == 'Global Admin' && !customerFreeTrial && isPlanLegacy && !isConsumerPlan">
                        <span class="vs-input--label">Additional Users</span>
                        <v-select v-model="additionalUsers" :options="additionalUsersOptions" :clearable="false" />
                        <p class="float-left w-full mt-2">These are additional users. Each account includes 3 users.</p>
                    </div>
                    <div class="vx-col w-full mb-6" v-if="!isConsumerPlan">
                        <vs-checkbox v-model="customerServiceSelectionEnabled">Change Company's Service Selection</vs-checkbox>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-4" v-if="customerServiceSelectionEnabled && !isConsumerPlan">
                        <vs-checkbox v-model="customerAnonymousVisitorId" @change="anonymousVisitorIdentificationAction()">Anonymous Visitor Identification</vs-checkbox>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full" v-if="customerServiceSelectionEnabled && !isConsumerPlan">
                        <vs-checkbox v-model="customerCallTracking">Call Tracking</vs-checkbox>
                    </div>
                    <div class="vx-col lg:w-1/2 w-full mb-4" v-if="customerServiceSelectionEnabled && !isConsumerPlan">
                        <vs-checkbox v-model="customerZapierIntegration" :disabled="!customerAnonymousVisitorId">Zapier Integration</vs-checkbox>
                    </div>
                    <!-- <div class="vx-col lg:w-1/2 w-full mb-4" v-if="customerServiceSelectionEnabled && !isConsumerPlan">
                        <vs-checkbox v-model="customerReviews">Reviews</vs-checkbox>
                    </div> -->
                    <div class="vx-col lg:w-1/2 w-full mb-4" v-if="customerServiceSelectionEnabled && !isConsumerPlan">
                        <vs-checkbox v-model="customerClientEmail" :disabled="!customerAnonymousVisitorId">Client Email Tracking</vs-checkbox>
                    </div>
                     <div class="vx-col w-full my-6" v-if="!customerFreeTrial && isPlanStandard && customerPreventDelete  && !isConsumerPlan">
                        <p class="float-left w-full mt-2">Additional Who's Shopping Campaigns</p>
                        <p class="float-left w-full ml-5 my-4">You currently have <span><b>{{customCampaignsPurchased}}</b></span> additional Who's Shopping Campaigns.</p>
                        <vs-button color="primary" type="filled" class=" ml-8 sm:w-auto w-full" @click="purchaseAdditionalCampaignPopup = true, campaignCountSummaryForCustomer()">Purchase/Remove Additional Campaigns</vs-button>
                    </div>
                </div>
            </form>
        </template>
        <div class="float-right mt-6 sm:w-auto w-full mb-8">
            <vs-button v-if="initialRole == 'Global Admin' && !this.$store.state.isReseller && !customerFreeTrial" color="success" type="filled" class="sm:w-auto ml-0 mb-2 sm:mb-0 w-full" @click="terminateAccountPopup = true">
                {{ LabelConstant.buttonLabelTerminate }}
            </vs-button>
            <vs-button v-if="initialRole == 'Global Admin' && !this.$store.state.isReseller && !customerFreeTrial" color="success" type="filled" class="sm:ml-4 ml-0 sm:w-auto w-full" @click="getCreateResellerInfo()">
                {{ LabelConstant.buttonLabelMakeReseller }}
            </vs-button>
            <vs-button v-if=" this.$store.state.isReseller && !customerFreeTrial" color="danger" type="filled" class="sm:ml-4 ml-0 sm:w-auto w-full" @click="endPaidAccountPopup = true">
                {{ LabelConstant.buttonLabelEndPaidAccount }}
            </vs-button>
            <vs-button v-if="initialRole == 'Global Admin' && !this.$store.state.isReseller && customerFreeTrial" color="success" type="filled" class="sm:ml-4 ml-0 sm:w-auto w-full" @click="disableMarketingPopup = true">
                {{ LabelConstant.buttonLabelDisableMarketing }}
            </vs-button>
            <vs-button v-if="initialRole == 'Global Admin' && customerFreeTrial" color="success" type="filled" class="sm:ml-4 ml-0 sm:w-auto w-full" @click="extendFreeTrial = true">
                {{ LabelConstant.buttonLabelExtendTrial }}
            </vs-button>
            <vs-button color="primary" type="filled" class="sm:ml-4 ml-0 sm:w-auto w-full" @click="updateCustomer()">
                {{ LabelConstant.buttonLabelUpdateCustomer }}
            </vs-button>
            <vs-button @click="cancelCreateCustomer()" color="grey" type="border" class="sm:ml-4 ml-0 sm:w-auto w-full grey-btn">
                {{ LabelConstant.buttonLabelCancel }}
            </vs-button>
        </div>

        <!-- if Add Cookie Consent remove -->
        <vs-popup title="Warning" :active.sync="identifiedVisitorChangeWarningPopup">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col w-full text-left">
                        <p>Warning, you have chosen to reduce the allow identified visitors count.  If the customer currently has identified more visitors than this number, you will still be charged for the current count.  Would you like to make this change?</p>
                    </div>
                </div>
            </template>
            <div class="text-right mt-6 mb-8">
                <vs-button color="primary" type="filled" class="mr-2" @click="identifiedVisitorChangeWarningPopup=false">{{ LabelConstant.buttonLabelOk }}</vs-button>
            </div>
        </vs-popup>

        <!-- Convert free to paid account -->
        <vs-popup title="End Free Trial" :active.sync="convertFreeToPaidPlanPopup">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="coming-soon w-1/2 py-5 mx-auto">
                        <img src="../../../src/assets/images/paid-plan.jpg" class="d-inline-block w-full"/>
                    </div>
                    <h2>End free trial and convert to a paid account</h2>
                </div>
            </div>
            <template>
                <form data-vv-scope="convertFreeToPaidAccountForm">
                    <div class="vx-row md:mx-5 mt-8">
                        <div class="vx-col  w-full mb-6">
                            <span class="vs-input--label pr-4 w-full">Select Plan</span>
                            <v-select
                                v-model="convertedPlanId"
                                :options="customerPaidPlanOptions"
                                :reduce="options => options.Key"
                                label="Value"
                                :clearable="false"
                                class="w-full"
                                name="convertedPlanId"
                                v-validate="'required'"
                            />
                            <span class="text-danger text-sm">{{errors.first("convertFreeToPaidAccountForm.convertedPlanId")}}</span>
                            <p class="pl-2">Your account will be billed based on your plan selection.</p>
                        </div>
                    </div>
                </form>
            </template>
            <div class="float-right mt-4 w-full sm:w-auto mb-8">
                <vs-button type="filled" class="w-full sm:w-auto mt-2" color="primary" @click="convertToPaidAccountV2()">Convert Account</vs-button>
                <vs-button   color="grey" type="border" class="grey-btn ml-0 sm:ml-4 w-full sm:w-auto mt-2" @click="cancelFreeToPaidConvert()" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </vs-popup>
    </vs-popup>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import {
    Validator
} from "vee-validate";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import store from "../../store/store";
import VueJwtDecode from "vue-jwt-decode";
import VueNumeric from 'vue-numeric'

const dict = {
    custom: {
        customerCompanyName: {
            required: "A Company Name is required"
        },
        customerDomainName: {
            required: "A Domain Name is required"
        },
        customerFirstName: {
            required: "First name must be more than 1 and less than 300 characters"
        },
        customerLastName: {
            required: "Last name must be more than 1 and less than 300 characters"
        },
        customerEmail: {
            required: "An Email is required",
            email: "Please enter a valid email address"
        },
        customerPhone: {
            required: "Please enter a valid phone number"
        },
        customerNewPassword: {
            required: "The New password field is required.",
            min: "The New password must be at least 6 characters long."
        },
        customerConfirmPassword: {
            required: "The Confirm New Password field is required.",
            min: "The Confirm New Password must be at least 6 characters long.",
            confirmed: "The new password and confirmation password do not match."
        },
        customerTimeZone: {
            required: "Please select Time Zone."
        },
        customerPlanId: {
            required: "Please select plan."
        },
        convertedPlanId: {
            required: "Please select plan."
        },
        resellerCompanyName: {
            required: "Reseller name must be more than 1 and less than 300 characters."
        },
        resellerLegalName: {
            required: "Reseller name must be more than 1 and less than 300 characters."
        },
        resellerDomainName: {
            required: "Domain name must be more than 1 and less than 300 characters."
        },
        resellerActualDomainName: {
            required: "An Actual Domain name must be more than 1 and less than 300 characters."
        },
        resellerMainPhone: {
            required: "Please enter a valid phone number."
        },
        resellerSalesPhone: {
            required: "Please enter a valid phone number."
        },
        resellerSupportPhone: {
            required: "Please enter a valid phone number."
        },
        resellerGeneralEmail: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },
        resellerSalseEmail: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },
        resellerSupportEmail: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },
        resellerSendAsEmail: {
            required: "Please enter email address.",
            email: "Please enter a valid email address."
        },

        companyName: {
            required: "Please enter company name"
        },
        companyDomain : {
            required: "Please enter company domain"
        },
        companyAddress : {
            required: "Please enter company address"
        },
        companyCity : {
            required: "Please enter company city"
        },
        companyState : {
            required: "Please enter company state"
        },
        companyZipcode : {
            required: "Please enter company zipcode"
        },
        companyPhone : {
            required: "Please enter company phone"
        },
        companySupportEmail : {
            required: "Please enter company support email",
            email: "Please enter a valid email address."
        },
        identifiedVisitor: {
            required: "Please enter number of visitors",
            numeric: "Please enter number only"
        }
    }
};
Validator.localize("en", dict);

export default {
    components: {
        VxCard,
        vSelect,
        downloadExcel: JsonExcel,
        Datepicker,
        VueJwtDecode,
        VueNumeric
    },
    data() {
        return {
            addIsCookieConsentReadonly: false,
            addEditCustomerValidationWarningPopup: false,
            isPlusEmployeeIncludedInPlan: false,
            isPlusEmployeeEnabled: false,
            isConsumerPlan: false,
            cookieConsentBanner: false,
            companyName: null,
            companyDomain: null,
            companyAddress: null,
            companyAddress2: null,
            companyCity: null,
            companyState: null,
            companyZipcode: null,
            companyPhone: null,
            companySupportEmail: null,
            identifiedVisitorTemp: 0,
            identifiedVisitor: 0,
            isIdentifiedVisitorVisible:false,
            identifiedVisitorChangeWarningPopup: false,
            identifiedVisitorUnitCost: 0,
            isTrusted: false,

            resellerIsLegacyOnly: true,
            activeFreeTrialsIncluded: 0,
            activeFreeTrials: 0,
            freeTrialsAvailableToProvision: 0,

            excelFields: {
                Id: "Id",
                "Company Name": "Company_Name",
                "Domain Name": "Domain_Name",
                Status: "Free_Trial",
                "Free Trial Exp": "Free_Trial_Expiration",
                "Date Created": "Date_Created",
                Account_Id: "Company_Id"
            },
            excelData: [],
            json_meta: [
                [{
                    key: "charset",
                    value: "utf-8"
                }]
            ],

            resellerCustomers: [],

            addCustomerPopup: false,
            customerCompanyId: null,
            customerCompanyName: null,
            customerDomainName: null,
            customerFreeTrial: true,
            customerPreventDelete: false,
            customerFirstName: null,
            customerLastName: null,
            customerEmail: null,
            customerPhone: null,
            customerRole: null,
            customerNewPassword: null,
            customerConfirmPassword: null,
            customerTimeZone: null,
            customerPlanId: null,
            customerActive: true,
            customerSendSetupEmail: true,
            customerServiceSelectionEnabled: false,
            customerAnonymousVisitorId: false,
            customerClientEmail: false,
            customerZapierIntegration: false,
            customerCallTracking: false,
            // customerReviews: false,
            customerPlanSelectionEnabled: false,
            customerRollName: null,

            convertedPlanId: null,
            convertFreeToPaidPlanPopup: false,

            roleOptions: [],
            timeZoneoptions: [],
            customerFreePlanOptions: [],
            customerPaidPlanOptions: [],

            editCustomerPopup: false,
            editRoleOptions: [],
            editCustomerData: [],

            enableBetaType: null,
            enableBetaMessage: null,
            enableBetaPopup: false,
            enableBetaButton: null,

            /* extend Free Trial */
            extendFreeTrial: false,
            extendedFreeTrialDate: new Date(),
            extendedFreeTrialDateParam: null,

            disableMarketingPopup: false,
            terminateAccountPopup: false,

            deleteCustomerPopup: false,
            deleteCustomerId: null,
            deleteCustomerName: null,

            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "100",
                    value: 100
                }
            ],

            additionalUsers: null,
            additionalUsersOptions: [],

            endPaidAccountPopup: false,

            createResellerPopup: false,
            resellerCompanyName: null,
            resellerLegalName: null,
            resellerDomainName: null,
            resellerActualDomainName: null,
            resellerMainPhone: null,
            resellerSalesPhone: null,
            resellerSupportPhone: null,
            resellerGeneralEmail: null,
            resellerSalseEmail: null,
            resellerSupportEmail: null,
            resellerSendAsEmail: null,

            initialRole: null,

            //Dynamic Sidebar Menu switched customer
            dynamicSidebarMenuItem: [],

            //Dynamic theme
            dynamicTheme: null,

            purchaseAdditionalCampaignPopup: false,
            customCampaignsIncluded: 0,
            customCampaignsPurchased: 0,
            customCampaignsProvisioned: 0,
            customCampaignsAvailableToProvision: 0,
            prebuiltCampaignsIncluded: 0,
            prebuiltCampaignsProvisioned: 0,
            prebuiltCampaignsAvailableToProvision: 0,
            campaignsAvailableToDelete: 0,

            isReseller: false,
            userActionType: 'add',
            addCustomCampaignRadioColor: 'success',
            addCustomCampaignTextColor: 'text-success',
            additionalCustomerCampaign : 1,
            additionalCustomCampaignOptions: [],


            deleteCustomCampaignRadioColor: 'secondary',
            deletionCustomCampaignTextColor: '',
            deletionCustomCampaign: 1,
            deletionCustomCampaignOptions: [],

            addDeleteCustomCampaignConfirmActive: false,
        };
    },
    mounted() {
        let that = this;
        //Filter Location Breakdown datatable record based on search parameter for excel
        setTimeout(() => {
            document
                .querySelector("#tableDataResellerCustomer .input-search")
                .addEventListener("keyup", function () {
                    that.excelData = that.filterExport(true);
                });
        }, 1000);

        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.resellerIsLegacyOnly = token[uid].resellerIsLegacyOnly
        this.isReseller = token[uid].isReseller
    },
    created() {
        this.getCustomerList();
        this.getTimeZones();
        this.decodeJWT();
        this.getAvailableRoles()
        this.getFreeTrialStats()
        this.listAvailableFreeTrialCustomerPlans()
        this.listAvailablePaidCustomerPlans()
        this.generateAdditionalUserOptions();
    },
    methods: {
        changePlusEmployee(){
            if(this.isConsumerPlan || this.isPlusEmployeeEnabled){
                this.cookieConsentBanner = true
            }else{
                this.cookieConsentBanner = false
            }
        },
        checkVisitorNumberLessThanCurrent(){
            if(this.identifiedVisitor < this.identifiedVisitorTemp){
                this.identifiedVisitorChangeWarningPopup = true
            }
            this.identifiedVisitorTemp = this.identifiedVisitor
        },
        getAllowedIdentifiedVisitorInfo(){
            this.$vs.loading();
            this.axios.get("/ws/Customer/GetAllowedIdentifiedVisitorInfo?plan_id="+this.customerPlanId+"&id="+this.customerCompanyId).then(response => {
                let data = response.data;
                this.isIdentifiedVisitorVisible = data.Is_Consumer_Plan
                this.isConsumerPlan = data.Is_Consumer_Plan
                this.isPlusEmployeeIncludedInPlan = data.Is_Plus_Employee_Included_In_Plan
                this.isPlusEmployeeEnabled = data.Is_Plus_Employee_Enabled
                if(this.addCustomerPopup){
                    this.identifiedVisitor = data.Default_Allowed_Visitors
                    this.addIsCookieConsentReadonly = data.Is_Cookie_Consent_Readonly
                    if(this.isConsumerPlan){
                        this.cookieConsentBanner = true
                    }
                }
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        onInputCompanyName(){
            this.companyName = this.customerCompanyName;
        },
        onInputCompanyDomain(){
            this.companyDomain = this.customerDomainName;
        },
        getFreeTrialStats() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/GetFreeTrialStats").then(response => {
                let data = response.data;
                this.activeFreeTrialsIncluded = data.Active_Free_Trials_Included
                this.activeFreeTrials = data.Active_Free_Trials
                this.freeTrialsAvailableToProvision = data.Free_Trials_Available_To_Provision
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        listAvailableFreeTrialCustomerPlans(){
            this.$vs.loading();
            this.axios.get("/ws/Customer/ListAvailableFreeTrialCustomerPlans").then((response) => {
                let data = response.data;
                this.customerFreePlanOptions = data;
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        listAvailablePaidCustomerPlans(){
            this.$vs.loading();
            this.axios.get("/ws/Customer/ListAvailablePaidCustomerPlans").then((response) => {
                let data = response.data;
                this.customerPaidPlanOptions = data;
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        //Get available users role like Globle Admin, Reseller Admin, Admin, User
        getAvailableRoles() {
            this.$vs.loading();
            this.axios.get("/ws/ConfigUser/GetAvailableRoles").then(response => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    if (data[i].Role_Name == 'Admin') {
                        this.roleOptions.push(data[i])
                    }
                }
                this.editRoleOptions = data
                this.customerRole = this.roleOptions[0].Id
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        //Get information by decoding the JWT
        decodeJWT() {
            let uid = this.$route.params.id;
            let jwtDecode = VueJwtDecode.decode(
                this.$store.state.authTokenVV[uid].token
            );
            this.initialRole = jwtDecode.initialRole;
        },
        //Get list of customers
        getCustomerList() {
            this.$vs.loading();
            this.axios
                .get("/ws/Customer/GetCustomerList")
                .then(response => {
                    let data = response.data;
                    this.resellerCustomers = data;
                    this.resellerCustomers.sort(this.GetSortOrder("Company_Name", "ASC")); //Pass the attribute to be sorted on
                    this.resellerCustomers.map(function (manipulatedData) {
                        manipulatedData.sortableCreatedDate = new Date(
                            manipulatedData.Date_Created
                        );
                        manipulatedData.sortableExpireDate = new Date(
                            manipulatedData.Free_Trial_Expiration
                        );
                        return manipulatedData;
                    });
                    this.excelData = this.filterExport();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get timezone for create and edit popup
        getTimeZones() {
            this.$vs.loading();
            this.axios
                .get("/ws/MyInfo/GetTimeZones")
                .then(response => {
                    let data = response.data;
                    this.timeZoneoptions = data;
                    for (let i = 0; i < this.timeZoneoptions.length; i++) {
                        if (this.timeZoneoptions[i].Key == 'Eastern Standard Time') {
                            this.customerTimeZone = this.timeZoneoptions[i].Key
                        }
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Delete customer
        deleteCustomer() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/DeleteCustomer?id=" + this.deleteCustomerId)
                .then(() => {
                    this.$refs.tableDataFormCapture.searchx = null;
                    this.getCustomerList();
                    this.deleteCustomerPopup = false;
                    this.deleteCustomerId = null;
                    this.deleteCustomerName = null;
                    this.$vs.notify({
                        title: "Success",
                        text: "Customer deleted successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        filterExport(search = false) {
            let data = [];
            let resellerCustomersData = !search ? this.resellerCustomers : this.$refs.tableDataResellerCustomer.datax;

            for (var i = 0; i < resellerCustomersData.length; i++) {
                data.push({
                    Id: resellerCustomersData[i].Id,
                    Company_Name: resellerCustomersData[i].Company_Name,
                    Domain_Name: resellerCustomersData[i].Domain_Name,
                    Free_Trial: resellerCustomersData[i].Free_Trial ? "Free Trial" : "Paid",
                    Free_Trial_Expiration: resellerCustomersData[i].Free_Trial_Expiration,
                    Date_Created: resellerCustomersData[i].Date_Created
                });
            }
            return data;
        },
        //Cancel create customer
        cancelCreateCustomer() {
            this.getCustomerList();
            this.addCustomerPopup = false;
            this.customerCompanyName = null;
            this.customerDomainName = null;
            this.customerFreeTrial = true;
            this.customerPreventDelete = false;
            this.customerFirstName = null;
            this.customerLastName = null;
            this.customerEmail = null;
            this.customerPhone = null;
            this.customerRole = this.roleOptions[0].Id;
            this.customerNewPassword = null;
            this.customerConfirmPassword = null;
            for (let i = 0; i < this.timeZoneoptions.length; i++) {
                if (this.timeZoneoptions[i].Key == 'Eastern Standard Time') {
                    this.customerTimeZone = this.timeZoneoptions[i].Key
                }
            }
            this.customerPlanId = null;
            this.customerActive = true;
            this.customerSendSetupEmail = true;
            this.customerServiceSelectionEnabled = false;
            this.customerAnonymousVisitorId = false;
            this.customerClientEmail = false;
            this.customerZapierIntegration = false;
           // this.customerReviews = false;
            this.customerCallTracking = false;
            this.customerRollName = null;

            this.editCustomerPopup = false;
            this.customerCompanyId = null;
            this.additionalUsers = "0";

            this.isConsumerPlan = false
            this.cookieConsentBanner = false;
            this.companyName = null;
            this.companyDomain = null;
            this.companyAddress = null;
            this.companyAddress2 = null;
            this.companyCity = null;
            this.companyState = null;
            this.companyZipcode = null;
            this.companyPhone = null;
            this.companySupportEmail = null;
            this.identifiedVisitor = 0;
            this.identifiedVisitorTemp = 0;
            this.isIdentifiedVisitorVisible = false;
            this.identifiedVisitorUnitCost = 0;
            this.isTrusted = false;
            this.isPlusEmployeeIncludedInPlan = false;
            this.isPlusEmployeeEnabled = false;

            setTimeout(() => {
                this.errors.clear("createCustomerForm");
                this.errors.clear("updateCustomerForm");
            }, 20);
        },
        //Create customer
        createCustomer() {
            this.$validator.validateAll("createCustomerForm").then(result => {
                if (result) {
                    let createCustomerParams = {
                        Company_Name: this.customerCompanyName,
                        Domain_Name: this.customerDomainName,
                        Free_Trial: this.customerFreeTrial,
                        First_Name: this.customerFirstName,
                        Last_Name: this.customerLastName,
                        Email: this.customerEmail,
                        Phone: this.customerPhone,
                        Role_Id: this.customerRole,
                        Password_New: this.customerNewPassword,
                        Password_Confirm: this.customerConfirmPassword,
                        Time_Zone_Id: this.customerTimeZone,
                        IsActive: this.customerActive,
                        Plan_Id: this.customerPlanId,
                        bSendSetupEmail: this.customerSendSetupEmail,
                        Show_Cookie_Consent: this.isConsumerPlan,
                        Enable_Cookie_Consent: this.cookieConsentBanner,
                        Cookie_Consent_Company_Name: this.companyName,
                        Cookie_Consent_Company_Domain: this.companyDomain,
                        Cookie_Consent_Company_Address: this.companyAddress,
                        Cookie_Consent_Company_Address2: this.companyAddress2,
                        Cookie_Consent_Company_City: this.companyCity,
                        Cookie_Consent_Company_State: this.companyState,
                        Cookie_Consent_Company_Zipcode: this.companyZipcode,
                        Cookie_Consent_Company_Phone: this.companyPhone,
                        Cookie_Consent_Company_Support_Email: this.companySupportEmail,
                        Allowed_Identified_Visitors: parseInt(this.identifiedVisitor),
                        Identified_Visitor_Unit_Cost: this.identifiedVisitorUnitCost,
                        Show_Enable_Plus_Employee: this.isPlusEmployeeIncludedInPlan,
                        Enable_Plus_Employee: this.isPlusEmployeeEnabled
                    };
                    this.$vs.loading();
                    this.axios.post("/ws/Customer/CreateCustomer", createCustomerParams)
                        .then(() => {
                            this.cancelCreateCustomer();
                            this.$vs.notify({
                                title: "Success",
                                text: "New customer added. Thank you for your business.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }else{
                    this.addEditCustomerValidationWarningPopup = true
                }
            });
        },
        //Get customer details
        getCustomer(id) {
            this.$vs.loading();
            this.axios.get("/ws/Customer/GetCustomer?id=" + id)
                .then(response => {
                    let data = response.data;
                    this.editCustomerData = data;

                    this.customerCompanyId = data.Id;
                    this.customerCompanyName = data.Company_Name;
                    this.customerDomainName = data.Domain_Name;
                    this.customerFreeTrial = data.Free_Trial;
                    this.customerPreventDelete = data.Prevent_Delete;
                    this.customerFirstName = data.First_Name;
                    this.customerLastName = data.Last_Name;
                    this.customerEmail = data.Email;
                    this.customerPhone = data.Phone;
                    this.customerRole = data.Role_Id;
                    this.customerNewPassword = data.Password_New;
                    this.customerConfirmPassword = data.Password_Confirm;
                    this.customerTimeZone = data.Time_Zone_Id;
                    this.customerActive = data.IsActive;
                    this.customerPlanId = data.Plan_Id
                    this.customerSendSetupEmail = data.bSendSetupEmail;
                    this.customerServiceSelectionEnabled = data.Service_Selection_Enabled;
                    this.customerAnonymousVisitorId = data.Anonymous_Visitor_Id;
                    this.customerClientEmail = data.Client_Email;
                    this.customerZapierIntegration = data.Zapier_Integration;
               //     this.customerReviews = data.Reviews;
                    this.customerPlanSelectionEnabled = data.Plan_Selection_Enabled;
                    this.customerCallTracking = data.Call_Tracking;
                    this.customerRollName = data.Roll_Name;
                    this.additionalUsers = data.Billing_Users_Additional.toString();

                    this.additionalUsersOptions = [];
                    for (let i = 0; i < 40; i++) {
                        this.additionalUsersOptions.push(i.toString());
                    }

                    this.cookieConsentBanner = data.Enable_Cookie_Consent;
                    this.companyName = data.Cookie_Consent_Company_Name;
                    this.companyDomain = data.Cookie_Consent_Company_Domain;
                    this.companyAddress = data.Cookie_Consent_Company_Address;
                    this.companyAddress2 = data.Cookie_Consent_Company_Address2;
                    this.companyCity = data.Cookie_Consent_Company_City;
                    this.companyState = data.Cookie_Consent_Company_State;
                    this.companyZipcode = data.Cookie_Consent_Company_Zipcode;
                    this.companyPhone = data.Cookie_Consent_Company_Phone;
                    this.companySupportEmail = data.Cookie_Consent_Company_Support_Email;
                    this.identifiedVisitor = data.Allowed_Identified_Visitors;
                    this.identifiedVisitorTemp = data.Allowed_Identified_Visitors;
                    this.identifiedVisitorUnitCost = data.Identified_Visitor_Unit_Cost;
                    this.isTrusted = data.Is_Trusted;

                    this.editCustomerPopup = true;
                    this.campaignCountSummaryForCustomer()
                    this.getAllowedIdentifiedVisitorInfo()
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Cancel enable beta
        cancelEnableBeta() {
            this.enableBetaType = null;
            this.enableBetaMessage = null;
            this.enableBetaPopup = false;
            this.enableBetaButton = null;
        },
        //Determine enable beta like email, zapier, contact, call tracking
        enableBeta() {
            if (this.enableBetaType == "email") {
                this.betaTesterClientEmail();
            } else if (this.enableBetaType == "zapier") {
                this.betaTesterZapier();
            } else if (this.enableBetaType == "contact") {
                this.betaTesterContactExport();
            } else {
                this.betaTesterCallTracking();
            }
        },
        //Enable email beta
        betaTesterClientEmail() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/BetaTesterClientEmail?id=" + this.customerCompanyId)
                .then(() => {
                    this.cancelEnableBeta();
                    this.$vs.notify({
                        title: "Success",
                        text: "This customer has entered our Client Email Beta.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Enable zapier beta
        betaTesterZapier() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/BetaTesterZapier?id=" + this.customerCompanyId)
                .then(() => {
                    this.cancelEnableBeta();
                    this.$vs.notify({
                        title: "Success",
                        text: "This customer has entered our Zapier Beta.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Enable contact beta
        betaTesterContactExport() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/BetaTesterContactExport?id=" + this.customerCompanyId)
                .then(() => {
                    this.cancelEnableBeta();
                    this.$vs.notify({
                        title: "Success",
                        text: "This customer has entered our Contact Export Beta.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Enable call tracking beta
        betaTesterCallTracking() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/BetaTesterCallTracking?id=" + this.customerCompanyId)
                .then(() => {
                    this.cancelEnableBeta();
                    this.$vs.notify({
                        title: "Success",
                        text: "This customer has entered our Call Tracking Beta.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //For Extend Free Trial
        customFormatter(date) {
            this.extendedFreeTrialDate = moment(date).format("dddd, MMM DD, YYYY");
            this.extendedFreeTrialDateParam = moment(date).format("YYYY-MM-DD");
        },
        //Extend Free Trial
        updateFreeTrialExpiration() {
            let updateFreeTrialExpiration = {
                Id: this.customerCompanyId,
                Free_Trial_Expiration: this.extendedFreeTrialDateParam
            };
            this.$vs.loading();
            this.axios.post("/ws/Customer/UpdateFreeTrialExpiration",updateFreeTrialExpiration)
                .then(() => {
                    this.extendFreeTrial = false;
                    this.$vs.notify({
                        title: "Success",
                        text: "Customer free trial extended.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        cancelFreeToPaidConvert(){
            this.convertFreeToPaidPlanPopup = false
            this.convertedPlanId = null
            setTimeout(() => {
                this.errors.clear("convertFreeToPaidAccountForm");
            }, 20);
        },
        convertToPaidAccountV2() {
            this.$validator.validateAll("convertFreeToPaidAccountForm").then(result => {
                if (result) {
                    this.$vs.loading();
                    this.axios.get("/ws/Customer/ConvertToPaidAccountV2?id=" + this.customerCompanyId+'&planid='+this.convertedPlanId).then(() => {
                        this.customerFreeTrial = false;
                        this.cancelEnableBeta();
                        this.cancelFreeToPaidConvert()
                        this.getCustomer(this.customerCompanyId)
                        this.$vs.notify({
                            title: "Success",
                            text: "Converted to a paid customer. Thank you for your business.",
                            color: "success",
                            iconPack: "feather",
                            icon: "icon-check",
                            position: "top-right",
                            time: 4000
                        });
                        this.$vs.loading.close();
                    }).catch(e => {
                        this.$vs.loading.close();
                        this.showError(e);
                        this.$vs.notify({
                            title: "Error",
                            text: e.response.data.Errors[0].Message,
                            color: "danger",
                            iconPack: "feather",
                            icon: "icon-alert-circle",
                            position: "top-right",
                            time: 4000
                        });
                    });
                }
            })
        },
        //Disable Marketing
        turnOffEmailMarketing() {
            this.$vs.loading();
            this.axios
                .get("/ws/Customer/TurnOffEmailMarketing?id=" + this.customerCompanyId)
                .then(() => {
                    this.disableMarketingPopup = false;
                    this.cancelEnableBeta();
                    this.$vs.notify({
                        title: "Success",
                        text: "The follow up email campaign has been disabled for this customer.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Anonymous Visitor Identification
        anonymousVisitorIdentificationAction() {
            if (!this.customerAnonymousVisitorId) {
                this.customerClientEmail = false;
                this.customerZapierIntegration = false;
            }
        },
        //Terminate Account
        cancelVVAccount() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/CancelVVAccount?id=" + this.customerCompanyId)
                .then(() => {
                    this.customerFreeTrial = true;
                    this.terminateAccountPopup = false;
                    this.$vs.notify({
                        title: "Success",
                        text: "Customer Account Terminated and Billing Disabled.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    let errorMessage;
                    this.$vs.loading.close();
                    this.showError(e);
                    if (e.response.data.Errors) {
                        errorMessage = e.response.data.Errors[0].Message;
                    } else {
                        errorMessage = e.response.data;
                    }
                    this.$vs.notify({
                        title: "Error",
                        text: errorMessage,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Update customer
        updateCustomer() {
            this.$validator.validateAll("updateCustomerForm").then(result => {
                if (result) {
                    let updateCustomerParams
                    if(this.initialRole == 'Global Admin'){
                        updateCustomerParams = {
                            Id: this.editCustomerData.Id,
                            User_Id: this.customerCompanyId,
                            Company_Name: this.customerCompanyName,
                            Domain_Name: this.customerDomainName,
                            Free_Trial: this.customerFreeTrial,
                            First_Name: this.customerFirstName,
                            Last_Name: this.customerLastName,
                            Email: this.customerEmail,
                            Phone: this.customerPhone,
                            Role_Id: this.customerRole,
                            Password_New: this.customerNewPassword,
                            Password_Confirm: this.customerConfirmPassword,
                            Time_Zone_Id: this.customerTimeZone,
                            IsActive: this.customerActive,
                            Plan_Id: this.customerPlanId,
                            bSendSetupEmail: this.customerSendSetupEmail,
                            Service_Selection_Enabled: this.customerServiceSelectionEnabled,
                            Anonymous_Visitor_Id: this.customerAnonymousVisitorId,
                            Client_Email: this.customerClientEmail,
                            Zapier_Integration: this.customerZapierIntegration,
                          //  Reviews: this.customerReviews,
                            Plan_Selection_Enabled: this.customerPlanSelectionEnabled,
                            Call_Tracking: this.customerCallTracking,
                            Roll_Name: this.customerRollName,
                            Billing_Users_Additional: parseInt(this.additionalUsers),
                            Show_Cookie_Consent: this.isConsumerPlan,
                            Enable_Cookie_Consent: this.cookieConsentBanner,
                            Cookie_Consent_Company_Name: this.companyName,
                            Cookie_Consent_Company_Domain: this.companyDomain,
                            Cookie_Consent_Company_Address: this.companyAddress,
                            Cookie_Consent_Company_Address2: this.companyAddress2,
                            Cookie_Consent_Company_City: this.companyCity,
                            Cookie_Consent_Company_State: this.companyState,
                            Cookie_Consent_Company_Zipcode: this.companyZipcode,
                            Cookie_Consent_Company_Phone: this.companyPhone,
                            Cookie_Consent_Company_Support_Email: this.companySupportEmail,
                            Allowed_Identified_Visitors: parseInt(this.identifiedVisitor),
                            Identified_Visitor_Unit_Cost: this.identifiedVisitorUnitCost,
                            Show_Enable_Plus_Employee: this.isPlusEmployeeIncludedInPlan,
                            Enable_Plus_Employee: this.isPlusEmployeeEnabled
                        };
                    }else{
                        updateCustomerParams = {
                            Id: this.editCustomerData.Id,
                            User_Id: this.customerCompanyId,
                            Company_Name: this.customerCompanyName,
                            Domain_Name: this.customerDomainName,
                            Free_Trial: this.customerFreeTrial,
                            First_Name: this.customerFirstName,
                            Last_Name: this.customerLastName,
                            Email: this.customerEmail,
                            Phone: this.customerPhone,
                            Role_Id: this.customerRole,
                            Password_New: this.customerNewPassword,
                            Password_Confirm: this.customerConfirmPassword,
                            Time_Zone_Id: this.customerTimeZone,
                            IsActive: this.customerActive,
                            Plan_Id: this.customerPlanId,
                            bSendSetupEmail: this.customerSendSetupEmail,
                            Service_Selection_Enabled: this.customerServiceSelectionEnabled,
                            Anonymous_Visitor_Id: this.customerAnonymousVisitorId,
                            Client_Email: this.customerClientEmail,
                            Zapier_Integration: this.customerZapierIntegration,
                          //  Reviews: this.customerReviews,
                            Call_Tracking: this.customerCallTracking,
                            Roll_Name: this.customerRollName,
                            Billing_Users_Additional: parseInt(this.additionalUsers),
                            Show_Cookie_Consent: this.isConsumerPlan,
                            Enable_Cookie_Consent: this.cookieConsentBanner,
                            Cookie_Consent_Company_Name: this.companyName,
                            Cookie_Consent_Company_Domain: this.companyDomain,
                            Cookie_Consent_Company_Address: this.companyAddress,
                            Cookie_Consent_Company_Address2: this.companyAddress2,
                            Cookie_Consent_Company_City: this.companyCity,
                            Cookie_Consent_Company_State: this.companyState,
                            Cookie_Consent_Company_Zipcode: this.companyZipcode,
                            Cookie_Consent_Company_Phone: this.companyPhone,
                            Cookie_Consent_Company_Support_Email: this.companySupportEmail,
                            Allowed_Identified_Visitors: parseInt(this.identifiedVisitor),
                            Identified_Visitor_Unit_Cost: this.identifiedVisitorUnitCost,
                            Show_Enable_Plus_Employee: this.isPlusEmployeeIncludedInPlan,
                            Enable_Plus_Employee: this.isPlusEmployeeEnabled
                        };
                    }
                    this.$vs.loading();
                    this.axios.post("/ws/Customer/UpdateCustomer", updateCustomerParams)
                        .then(() => {
                            this.cancelCreateCustomer();
                            this.$vs.notify({
                                title: "Success",
                                text: "Customer modification successful.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }else{
                    this.addEditCustomerValidationWarningPopup = true
                }
            });
        },
        //End Paid Account and convert to free
        deleteCustomerBackToFreeTrial() {
            this.$vs.loading();
            this.axios.get("/ws/Customer/DeleteCustomerBackToFreeTrial?id=" + this.customerCompanyId)
                .then(() => {
                    this.endPaidAccountPopup = false;
                    this.customerFreeTrial = true;
                    this.getCustomer(this.customerCompanyId)
                    this.$vs.notify({
                        title: "Success",
                        text: "This customer has been moved from a paid account to an expired free trial. You will no longer be billed for this account.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Make Reseller
        getCreateResellerInfo() {
            this.resellerCompanyName = this.editCustomerData.Company_Name;
            this.resellerDomainName = ".id-visitors.com";
            this.resellerActualDomainName = this.editCustomerData.Domain_Name;

            this.editCustomerPopup = false;
            this.createResellerPopup = true;
        },
        //Cancel Create New Reseller
        cancelCreateOrganization() {
            this.resellerCompanyName = null;
            this.resellerLegalName = null;
            this.resellerDomainName = null;
            this.resellerActualDomainName = null;
            this.resellerMainPhone = null;
            this.resellerSalesPhone = null;
            this.resellerSupportPhone = null;
            this.resellerGeneralEmail = null;
            this.resellerSalseEmail = null;
            this.resellerSupportEmail = null;
            this.resellerSendAsEmail = null;
            this.createResellerPopup = false;

            setTimeout(() => {
                this.errors.clear("createResellerForm");
            }, 20);
        },
        //Create New Reseller
        createOrganization() {
            this.$validator.validateAll("createResellerForm").then(result => {
                if (result) {
                    let createOrganizationParams = {
                        Id: this.editCustomerData.Id,
                        Customer_Id: this.editCustomerData.Id,
                        makeReseller: true,
                        Org_Name: this.resellerCompanyName,
                        Org_Name_Display: this.resellerLegalName,
                        Domain_Name: this.resellerDomainName,
                        Org_Actual_domain_Name: this.resellerActualDomainName,
                        Org_Phone_Main: this.resellerMainPhone,
                        Org_Phone_Sales: this.resellerSalesPhone,
                        Org_Phone_Support: this.resellerSupportPhone,
                        Org_Email_General: this.resellerGeneralEmail,
                        Org_Email_Sales: this.resellerSalseEmail,
                        Org_Email_Support: this.resellerSupportEmail,
                        Email_From_Address: this.resellerSendAsEmail
                    };
                    this.$vs.loading();
                    this.axios.post("/ws/Organization/CreateOrganization",createOrganizationParams)
                        .then(() => {
                            this.cancelCreateOrganization();
                            this.getCustomerList()
                            this.$vs.notify({
                                title: "Success",
                                text: "Reseller created successfully.",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }
            });
        },
        //Login as Customer in new browser tab
        loginAsCustomer(id) {
            this.$vs.loading();
            this.axios
                .get("/ws/Customer/LoginAsCustomer?id=" + id)
                .then(response => {
                    let data = response.data;

                    //Get array of tokens from local storage
                    let VVtoken = localStorage.getItem("VVtoken");
                    let token = JSON.parse(VVtoken);
                    let currentUid = this.$route.params.id;

                    //Use authentication token of the newly switched customer to get list of dynamic menu items
                    this.axios.defaults.headers.common["Authorization"] =
                        "Bearer " + data.token;
                    let url = "Account/GetInterfaceOptions";
                    this.axios
                        .get(url)
                        .then(response => {
                            //Revert authentication token of current logged user
                            this.axios.defaults.headers.common["Authorization"] =
                                "Bearer " + token[currentUid].token;
                            let menuData = response.data;
                            this.dynamicSidebarMenuItem = menuData.MenuItems;
                            this.dynamicTheme = menuData.DashboardThemeV2;
                            this.dynamicTheme.tempActionButtonColor = this.RGBToHex(
                                menuData.DashboardThemeV2.ActionButtonColor
                            );
                            this.dynamicTheme.tempChartColor1 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor1
                            );
                            this.dynamicTheme.tempChartColor2 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor2
                            );
                            this.dynamicTheme.tempChartColor3 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor3
                            );
                            this.dynamicTheme.tempChartColor4 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor4
                            );
                            this.dynamicTheme.tempChartColor5 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor5
                            );
                            this.dynamicTheme.tempChartColor6 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor6
                            );
                            this.dynamicTheme.tempChartColor7 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor7
                            );
                            this.dynamicTheme.tempChartColor8 = this.RGBToHex(
                                menuData.DashboardThemeV2.ChartColor8
                            );
                            this.dynamicTheme.tempFirstColor = this.RGBToHex(
                                menuData.DashboardThemeV2.FirstColor
                            );
                            this.dynamicTheme.tempSecondColor = this.RGBToHex(
                                menuData.DashboardThemeV2.SecondColor
                            );
                            this.dynamicTheme.tempThirdColor = this.RGBToHex(
                                menuData.DashboardThemeV2.ThirdColor
                            );
                            this.dynamicTheme.tempFourthColor = this.RGBToHex(
                                menuData.DashboardThemeV2.FourthColor
                            );
                            this.dynamicTheme.tempNavbarColor = this.RGBToHex(
                                menuData.DashboardThemeV2.NavbarColor
                            );
                            this.dynamicTheme.tempThemeColor = this.RGBToHex(
                                menuData.DashboardThemeV2.ThemeColor
                            );

                            let redirectDefaultUrl = this.getRedirectDefaultUrl(menuData.DefaultHomePage)
                            //Checked loged in or not
                            for (let i = 1; i < token.length; i++) {
                                if (token[i].id == id) {
                                    if (!token[i].isLogedIn && !token[i].token) {
                                        token[i].token = data.token;
                                        token[i].isLogedIn = true;
                                        token[i].menuItems = this.dynamicSidebarMenuItem;
                                        token[i].themeDetail = this.dynamicTheme;
                                        token[i].isReseller = menuData.Reseller;
                                        token[i].isActiveFreeTrial = menuData.IsActiveFreeTrial;
                                        token[i].isExpiredFreeTrial = menuData.FreeTrialIsExpired;
                                        token[i].resellerIsLegacyOnly = menuData.Reseller_Is_Legacy_Only;
                                        token[i].ShowContactReport = menuData.ShowContactReport;
                                        token[i].userDetails = menuData.UserInformation;
                                        token[i].maxLocalNumbers = menuData.MaxLocalNumbers;
                                        token[i].DefaultHomePage = menuData.DefaultHomePage;
                                        token[i].showBillingV2 = menuData.ShowBillingV2;
                                        token[i].showGuidedTour = menuData.ShowGuidedTour;
                                        token[i].screenItems = menuData.ScreenItems;
                                        token[i].isConsumerPlan = menuData.Is_Consumer_Plan;
                                        token[i].isReportSettingsReadOnly = menuData.Is_Report_Settings_ReadOnly;
                                        token[i].enableZapierIntegration = menuData.EnableZapierIntegration;
                                        token[i].isResellerPrimaryAccount = menuData.Is_Reseller_Primary_Account;
                                        token[i].isResellerSubaccount = menuData.Is_Reseller_Subaccount;

                                        localStorage.setItem("VVtoken", JSON.stringify(token));
                                        store.dispatch("updateAuthToken", token);

                                        window.open(redirectDefaultUrl + i, "_blank");
                                        this.$vs.loading.close();
                                        return false;
                                    } else {
                                        window.open(redirectDefaultUrl + i, "_blank");
                                        this.$vs.loading.close();
                                        return false;
                                    }
                                }
                            }
                            //Push new brand login details array
                            token.push({
                                id: id,
                                token: data.token,
                                refreshToken: null,
                                isLogedIn: true,
                                menuItems: this.dynamicSidebarMenuItem,
                                themeDetail: this.dynamicTheme,
                                isReseller: menuData.Reseller,
                                isActiveFreeTrial: menuData.IsActiveFreeTrial,
                                isExpiredFreeTrial: menuData.FreeTrialIsExpired,
                                resellerIsLegacyOnly: menuData.Reseller_Is_Legacy_Only,
                                ShowContactReport: menuData.ShowContactReport,
                                userDetails: menuData.UserInformation,
                                maxLocalNumbers: menuData.MaxLocalNumbers,
                                DefaultHomePage: menuData.DefaultHomePage,
                                showBillingV2: menuData.ShowBillingV2,
                                showGuidedTour: menuData.ShowGuidedTour,
                                screenItems : menuData.ScreenItems,
                                isConsumerPlan : menuData.Is_Consumer_Plan,
                                isReportSettingsReadOnly: menuData.Is_Report_Settings_ReadOnly,
                                enableZapierIntegration: menuData.EnableZapierIntegration,
                                isResellerPrimaryAccount: menuData.Is_Reseller_Primary_Account,
                                isResellerSubaccount: menuData.Is_Reseller_Subaccount
                            });

                            //Update local storage and vue storage with new brand login
                            localStorage.setItem("VVtoken", JSON.stringify(token));
                            store.dispatch("updateAuthToken", token);

                            //Redirect brand login to new tab window
                            let uid = token.length - 1;
                            window.open(redirectDefaultUrl + uid, "_blank");

                            this.$vs.loading.close();
                        })
                        .catch(() => {
                            this.axios.defaults.headers.common["Authorization"] =
                                "Bearer " + token[currentUid].token;
                            // this.showError(e);
                            this.logout();
                        });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        async campaignCountSummaryForCustomer(){
            this.$vs.loading();
            await this.axios.get("/ws/FindAnyoneCampaign/CampaignCountSummaryForCustomer?id="+this.customerCompanyId).then(response => {
                let data = response.data;
                this.customCampaignsIncluded = data.Custom_Campaigns_Included
                this.customCampaignsPurchased = data.Custom_Campaigns_Purchased
                this.customCampaignsProvisioned = data.Custom_Campaigns_Provisioned
                this.customCampaignsAvailableToProvision = data.Custom_Campaigns_Available_To_Provision
                this.prebuiltCampaignsIncluded = data.Prebuilt_Campaigns_Included
                this.prebuiltCampaignsProvisioned = data.Prebuilt_Campaigns_Provisioned
                this.prebuiltCampaignsAvailableToProvision = data.Prebuilt_Campaigns_Available_To_Provision
                this.generateDeletionCustomCampaignOptions()
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        generateDeletionCustomCampaignOptions(){
            this.deletionCustomCampaignOptions = []
            this.campaignsAvailableToDelete = 0;
            if(this.customCampaignsPurchased > 0 && this.customCampaignsProvisioned > 0){
                this.campaignsAvailableToDelete = (this.customCampaignsPurchased) - (this.customCampaignsProvisioned - this.customCampaignsIncluded)
            }else{
                this.campaignsAvailableToDelete = this.customCampaignsPurchased
            }
            for (let i = 1; i <= this.campaignsAvailableToDelete; i++) {
                this.deletionCustomCampaignOptions.push(i)
            }
        },
        generateAdditionalUserOptions(){
            this.additionalCustomCampaignOptions = []
            for (let i = 1; i <= 10; i++) {
                this.additionalCustomCampaignOptions.push(i)
            }
        },
        changeColor(){
            if(this.userActionType == 'add'){
                this.addCustomCampaignRadioColor = 'success'
                this.addCustomCampaignTextColor = 'text-success'
                this.deleteCustomCampaignRadioColor = 'secondary'
                this.deletionCustomCampaignTextColor = ''
            }else{
                this.addCustomCampaignRadioColor = 'secondary'
                this.addCustomCampaignTextColor = ''
                this.deleteCustomCampaignRadioColor = 'danger'
                this.deletionCustomCampaignTextColor = 'text-danger'
            }
        },
        cancelPurchase(){
            this.purchaseAdditionalCampaignPopup = false

            this.userActionType = 'add'
            this.addCustomCampaignRadioColor = 'success'
            this.addCustomCampaignTextColor = 'text-success'
            this.additionalCustomerCampaign = 1

            this.deleteCustomCampaignRadioColor = 'secondary'
            this.deletionCustomCampaignTextColor = ''
            this.deletionCustomCampaign = 1
        },
        purchaseAdditionalCampaign(){
            let requestParams = {
                CompanyId: this.customerCompanyId,
                Quantity: this.deletionCustomCampaign
            }
            this.$vs.loading();
            this.axios.post("/ws/Customer/PurchaseAdditionalCampaign", requestParams).then(() => {
                this.$vs.notify({
                    title:'Success',
                    text: 'Your additional campaign have been updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.addDeleteCustomCampaignConfirmActive = false
                this.cancelPurchase()
                this.campaignCountSummaryForCustomer()
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
                this.showError(e);
            });
        },
        deletePurchasedCampaign(){
            let requestParams = {
                CompanyId: this.customerCompanyId,
                Quantity: this.additionalCustomerCampaign
            }
            this.$vs.loading();
            this.axios.post("/ws/Customer/RemoveAdditionalCampaign", requestParams).then(() => {
                this.$vs.notify({
                    title:'Success',
                    text: 'Your additional campaign have been updated successfully.',
                    color: "success",
                    iconPack: 'feather',
                    icon:'icon-check',
                    position: "top-right",
                    time:4000
                });
                this.addDeleteCustomCampaignConfirmActive = false
                this.cancelPurchase()
                this.campaignCountSummaryForCustomer()
                this.$vs.loading.close();
            }).catch(e => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title:'Error',
                    text: e.response.data.Errors[0].Message,
                    color: "danger",
                    iconPack: 'feather',
                    icon:'icon-alert-circle',
                    position:"top-right",
                    time:4000
                });
                this.showError(e);
            });
        },
        setDefaultPlan(actionType){
            if(actionType == 'addCustomer'){
                this.customerPlanId = this.customerFreePlanOptions.length == 1 ? this.customerFreePlanOptions[0].Key : null
                if(this.customerPlanId){
                    this.getAllowedIdentifiedVisitorInfo()
                }
            }else{
                this.convertedPlanId = this.customerPaidPlanOptions.length == 1 ? this.customerPaidPlanOptions[0].Key : null
            }
        }
    },
    computed: {
        /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
         * showingSearchedDataTo()
		 *  */
        isTableRecordEntriesDisplay() {
            if (this.resellerCustomers.length > 0) {
                if (this.$refs.tableDataResellerCustomer.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.resellerCustomers.length > 0) {
                if (
                    this.$refs.tableDataResellerCustomer.searchx == null ||
                    this.$refs.tableDataResellerCustomer.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showingDataFrom: function () {
            if (this.resellerCustomers.length > 0) {
                return (
                    (this.$refs.tableDataResellerCustomer.currentx - 1) * this.perPage + 1
                );
            }
        },
        showingDataTo: function () {
            if (this.resellerCustomers.length > 0) {
                let totalPages = this.$refs.tableDataResellerCustomer.getTotalPages;
                if (totalPages == this.$refs.tableDataResellerCustomer.currentx) {
                    return (
                        (this.$refs.tableDataResellerCustomer.currentx - 1) * this.perPage +
                        this.$refs.tableDataResellerCustomer.datax.length
                    );
                } else {
                    return this.$refs.tableDataResellerCustomer.currentx * this.perPage;
                }
            }
        },
        totalSearchedData: function () {
            if (this.resellerCustomers.length > 0) {
                return this.$refs.tableDataResellerCustomer.queriedResults.length;
            }
        },
        showingSearchedDataTo: function () {
            if (this.resellerCustomers.length > 0) {
                let totalPages = this.$refs.tableDataResellerCustomer
                    .getTotalPagesSearch;
                if (totalPages == this.$refs.tableDataResellerCustomer.currentx) {
                    return (
                        (this.$refs.tableDataResellerCustomer.currentx - 1) * this.perPage +
                        this.$refs.tableDataResellerCustomer.datax.length
                    );
                } else {
                    return this.$refs.tableDataResellerCustomer.currentx * this.perPage;
                }
            }
        },
        isPlanLegacy(){
            if(this.customerPlanId){
                let planOptions = this.customerFreeTrial ? this.customerFreePlanOptions : this.customerPaidPlanOptions
                let planName = null
                for (let i = 0; i < planOptions.length; i++) {
                    planName = this.customerPlanId == planOptions[i].Key ? planOptions[i].Value : null
                    if(planName)
                    break;
                }
                if(planName && planName.toLowerCase().includes("legacy")){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        },
        isPlanStandard(){
            if(this.customerPlanId){
                if(this.customerPlanId.toLowerCase().indexOf("legacy") < 0){
                    return true
                }else{
                    return false
                }
            }else{
                return false
            }
        }
    }
};
</script>
